
import { Component, Vue } from 'vue-property-decorator';
import LogoutModal from "@/components/modal/LogoutModal.vue";
import AlertMessage from "@/components/AlertMessage.vue";

@Component({
  components: {
    AlertMessage,
    LogoutModal,
  }
})
export default class TopNav extends Vue {
  messageBoxActive = false;


  logout(){
    this.$bvModal.show('logout-modal');
  }

  messageBox(payload: boolean) {
    this.messageBoxActive = payload;
  }

  async created() {
    await this.$store.dispatch('socketStore/emitAdminHeaderInfo');
  }

  get projectList() {
    return this.$store.getters["socketStore/projectList"];
  }

  get projectCount() {
    return this.$store.getters['socketStore/projectCount'];
  }

  get userCount() {
    return this.$store.getters["socketStore/userCount"];
  }

  get questionCount() {
    return this.$store.getters["socketStore/questionCount"];
  }

  get existsNew() {
    return this.userCount || this.questionCount;
  }
}
