import Vue from 'vue'
import VueRouter, {Route, RouteConfig} from 'vue-router'
import store from "@/store";
import app from '@/main';

import Views from "@/views/Index.vue";
import Login from "@/views/Login.vue";
import ProjectList from "@/views/project/board/ProjectList.vue";
import ProjectView from "@/views/project/board/ProjectView.vue";
import UserList from '@/views/user/UserList.vue';
import UserView from '@/views/user/UserView.vue';
import UserQuestionView from '@/views/user/UserQuestionView.vue';
import UserQuestionReply from '@/views/user/UserQuestionReply.vue';
import QuestionView from "@/views/question/QuestionView.vue";
import QuestionReply from "@/views/question/QuestionReply.vue";
import QuestionList from "@/views/question/QuestionList.vue";
import AdminHelpList from "@/views/help/AdminHelpList.vue";
import AdminHelpRead from "@/views/help/AdminHelpRead.vue";
import AdminHelpModify from "@/views/help/AdminHelpModify.vue";
import AdminHelpWrite from "@/views/help/AdminHelpWrite.vue";

import K8sIndex from '@/views/admin/k8s/index.vue';
import K8sNamespace from '@/views/admin/k8s/apps/namespaces.vue';
import K8sDeployment from '@/views/admin/k8s/apps/deployments.vue';
import K8sPod from '@/views/admin/k8s/apps/pods.vue';
import K8sPodSpecific from '@/views/admin/k8s/apps/pod-specific.vue';

import K8sAppsIndex from '@/views/admin/k8s/apps/index.vue';
import K8sNodesIndex from '@/views/admin/k8s/nodes/index.vue';

import K8sNodes from '@/views/admin/k8s/nodes/nodes.vue';
import K8sNode from '@/views/admin/k8s/nodes/node-specific.vue';

import K8sNetworkIndex from '@/views/admin/k8s/networks/index.vue';
import K8sIngress from '@/views/admin/k8s/networks/ingresses.vue';

import K8sIngressSpecific from '@/views/admin/k8s/networks/ingress-specific.vue';
import AdminBlogList from "@/views/blog/AdminBlogList.vue";
import AdminBlogWrite from "@/views/blog/AdminBlogWrite.vue";
import AdminBlogView from "@/views/blog/AdminBlogView.vue";
import AdminBlogEdit from "@/components/blog/AdminBlogEdit.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: '',
    redirect: '/admin/list'
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { unauthorized: true },
  },
  {
    path: '/admin/list',
    name: 'boardList',
    component: ProjectList,
    children: [
      { path: ':id', name: 'board-specific', component: ProjectView },
    ]
  },
  {
    path: '/admin/user',
    name: 'userList',
    component: UserList,
    children: [
      {
        path: 'question/:listId',
        name: 'user-question',
        component: UserQuestionView,
      },
      {
        path: 'question-reply/:listId/:level',
        name: 'user-question-reply',
        component: UserQuestionReply,
      },
      {
        path: ':id',
        name: 'user-specific',
        component: UserView,
      },
    ],
  },
  {
    path: '/admin/question',
    name: 'question-list',
    component: QuestionList,
    meta: { role: 'ADMIN' },
  },
  {
    path: '/admin/question/:listId',
    name: 'questionView',
    component: QuestionView,
    meta: {},
  },
  {
    path: '/admin/question-reply/:listId/:level?',
    name: 'board-question-reply',
    component: QuestionReply,
    meta: { role: 'ADMIN' },
  },
  {
    path: '/admin/qna',
    name: 'qna-list',
    component: AdminHelpList,
    meta: { role: 'ADMIN' },
  },
  {
    path: '/admin/blog',
    name: 'blog-list',
    component: AdminBlogList,
    meta: { role: 'ADMIN' },
  },
  {
    path: '/admin/blog-write',
    name: 'blog-write',
    component: AdminBlogWrite,
    meta: { role: 'ADMIN' },
  },
  {
    path: '/admin/blog-edit/:blogId',
    name: 'blog-edit',
    component: AdminBlogEdit,
    meta: { role: 'ADMIN' },
  },
  {
    path: '/admin/blog-view/:blogId',
    name: 'blog-view',
    component: AdminBlogView,
    meta: { role: 'ADMIN' },
  },
  {
    path: '/admin/qna-read/:helpId',
    name: 'qna-read',
    component: AdminHelpRead,
    meta: { role: 'ADMIN' },
  },
  {
    path: '/admin/qna-modify/:helpId',
    name: 'qna-modify',
    component: AdminHelpModify,
    meta: { role: 'ADMIN' },
  },
  {
    path: '/admin/qna-write',
    name: 'qna-write',
    component: AdminHelpWrite,
    meta: { role: 'ADMIN' },
  },
  {
    path: '/admin/k8s',
    name: 'k8s',
    component: K8sIndex,
    children: [
      { path: 'apps', component: K8sAppsIndex,
        children: [
          {
            path: '', name: 'namespaces', component: K8sNamespace,
            children: [
              {
                path: ':namespace', name: 'deployment', component: K8sDeployment,
                children: [
                  {
                    path: ':deployment', name: 'pods', component: K8sPod,
                    children: [
                      {
                        path: ':pod', name: 'pod-specific', component: K8sPodSpecific,
                      },
                    ]
                  }
                ]
              },
            ]
          },
        ]
      },
      {
        path: 'nodes', component: K8sNodesIndex,
        children: [
          { path: '', name: 'nodes', component: K8sNodes,
            children: [
              { path: ':node', name: 'node-specific', component: K8sNode },
            ]
          },
        ]
      },
      {
        path: 'networks', component: K8sNetworkIndex,
        children: [
          {
            path: '', component: K8sIngress,
            children: [
              { path: ':namespace/:ingress', name: 'ingress-specific', component: K8sIngressSpecific },
            ]
          },
        ]
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
})


router.beforeEach(async (to: Route, from, next) => {
  try {
    const { matched, name } = to;
    const isUnauthorized = matched.some((record) => record.meta.unauthorized);
    const { token } = store.getters;

    if (token && name === 'login') return next('/');

    if (isUnauthorized) return next();

    if (!token) return next('login')

    try {
      const { result } = await store.dispatch('verify');
      if (!result) return next('login');
    } catch (e) {
      return next('login');
    }

    return next()
  } catch (e) {
    console.error(e);
    return next('/');
  }
});

export default router
