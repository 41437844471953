import {IArrayOption} from "@/interface/common";


export function boardQuestionInit(): IBOARDQuestion {
    return {
        _id: '',
        TITLE: '',
        CONTENTS: '',
        STATUS: QUESTION_STATUS.WAIT,
        TAG: [],
        FILES: [],
        REGIST_ID: '',
        REGIST_NAME: '',
        ANSWER: '',
        DT: '',
        UPDATE_NAME: '',
        UPDATE_ID: '',
        CATEGORY: '',
        TYPE: QUESTION_TYPE.SURVEY,
        USER_ID: '',
    };
}

export const enum QUESTION_TYPE {
    USER = '가입/회원정보 문의',
    SURVEY = '설문 진행 문의',
    TEMPLATE = '템플릿 문의',
    PAYMENT = '결제 문의',
    ETC_ERROR = '기타/오류',
}
export const enum QUESTION_STATUS {
    WAIT = '답변대기중',
    COMPLETE = '답변완료',
    CHECKING = '확인중',
}

export const QUESTION_STATUS_OPTION: IArrayOption[] = [
    { text: QUESTION_STATUS.WAIT, value: QUESTION_STATUS.WAIT },
    { text: QUESTION_STATUS.COMPLETE, value: QUESTION_STATUS.COMPLETE },
    { text: QUESTION_STATUS.CHECKING, value: QUESTION_STATUS.CHECKING },
];

export const enum HELP_CATEGORY {
    NOTICE = '공지사항',
    SURVEY = '문항유형',
    USE = '사용법',
}

export const HELP_CATEGORY_OPTION: IArrayOption[] = [
    { text: HELP_CATEGORY.SURVEY, value: HELP_CATEGORY.SURVEY },
    { text: HELP_CATEGORY.USE, value: HELP_CATEGORY.USE },
    { text: HELP_CATEGORY.NOTICE, value: HELP_CATEGORY.NOTICE },
];

type FILES = {
    _id: string,
    name: string,
    Location: string
}

export interface IBOARDQuestion {
    _id: string;
    TITLE: string;
    CONTENTS: string;
    STATUS: QUESTION_STATUS;
    TAG: string[];
    FILES: FILES[];
    REGIST_ID: string;
    REGIST_NAME: string;
    REG_DATE?: string;
    DT: Date | string;
    UPDATE_DT?: Date;
    UPDATE_ID: string;
    UPDATE_NAME: string;
    CATEGORY?: string;
    ANSWER: string;
    TYPE: QUESTION_TYPE;
    USER_ID: string;
}

export function helpInit(helpTab): IHelp {
    return {
        _id: '',
        TITLE: '',
        CONTENTS: '',
        TAG: [],
        FILES: [],
        REGIST_ID: '',
        REGIST_NAME: '',
        DT: '',
        CATEGORY: helpTab?HELP_CATEGORY.SURVEY:HELP_CATEGORY.NOTICE,
        READ_COUNT: 0,
    };
}

export interface IHelp {
    _id: string;
    TAG: string[];
    CATEGORY: HELP_CATEGORY;
    FILES: FILES[];
    READ_COUNT: number;
    DT: Date | string;
    REGIST_ID: string;
    REGIST_NAME: string;
    TITLE: string;
    CONTENTS: string;
}