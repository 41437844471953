
import {Component, Vue} from "vue-property-decorator";
import BlogTable from "@/components/blog/BlogTable.vue";

@Component({
  components: {
    BlogTable
  }
})
export default class AdminBlogList extends Vue{
  page = 1;
  search = '';
  searchType = 'title';
  totalRow = 0;
  perPage = 10;
  loading = false;
  blogList = [];
  showing = false;

  searchButtonHover = true;

  searchOptions=[
    { value: 'title', text: '제목' },
    { value: 'content', text: '내용' },
    { value: 'category', text: '카테고리' },
  ]
  categoryOptions = [
    { value: '기획조사', text: '기획조사' },
    { value: '리서치지식', text: '리서치지식' },
  ];

  created(){
    this.load();
  }

  async load(){
    this.loading = true;
    try{
      const params = {
        page: this.page,
        search: this.search,
        searchType: this.searchType,
      };
      const { data } = await this.axios({
        url: '/admin/board/blog/getBlogList',
        method: 'GET',
        params,
      });
      console.log(data);
      const { list, count, result, numberOfPages } = data;
      if(result){
        this.blogList = list;
        this.totalRow = count;
      }
    }catch (e){
      console.error(e);
    }
    this.loading = false;
  }
  get numberOfPages() {
    return Math.ceil(this.totalRow / this.perPage) || 1;
  }
  linkGen(page: number) {
    return this.makeQuery({ page });
  }
  makeQuery(queryObj: { [key: string]: string | number }) {
    const { query: routeQuery } = this.$route;
    const temp = { ...routeQuery, ...queryObj };
    const query = Object.entries(temp).map(entry => entry.join('=')).join('&');

    return `?${query}`;
  }

  blogView(blogId){
    this.$router.push(`/admin/blog-view/${blogId}`);
  }

  searchSubmit() {
    this.changeFilter({
      search: this.search,
      searchType: this.searchType,
    })
  }
  activeSearchButton() {
    this.searchButtonHover = !this.searchButtonHover;
  }
}
